/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useCallback } from 'react';
import { func } from 'prop-types';
import {
  Alert,
  Button,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Typography,
} from '@one-thd/sui-atomic-components';
import { useBogoImages } from '../../../hooks/useBogoImages';
import { useMessage } from '../../../hooks/useMessage';
import { usePromoCart } from '../../../hooks/usePromoCart';
import { usePromoDrawerModel } from '../../../hooks/usePromoDrawerModel';
import { usePromoModel } from '../../../hooks/usePromoModel';
import { usePromoPresentation } from '../../../hooks/usePromoPresentation';
import { DRAWER_TYPES, MESSAGE_KEY } from '../../../utils/constants';
import { BogoProductImageList } from '../../BogoProductImageList/BogoProductImageList';
import { ButtonGroup } from '../../ButtonGroup/ButtonGroup';
import { PromoList } from '../../PromoList/PromoList';
import { ViewCartButton } from '../../ViewCartButton/ViewCartButton';
import { PromoDrawerHeader } from './subcomponents/PromoDrawerHeader';
import { PromoList2DrawerDataModel } from '../../../models/PromoList2DrawerDataModel';
import { OverflowContainer } from '../../OverflowContainer/OverflowContainer';

export const PromoList2Drawer = ({ onBack, onClose, onDrawerViewChange }) => {
  const {
    displayableSecondListItemIds,
    isForwardsB1gy,
    isForwardsBxg1,
    isDollarThresholdBogo,
    src1EligibilityCriteria,
    secondListPlaceholderItemIds,
    secondListObserver,
    tgt1EligibilityCriteria,
    type
  } = usePromoModel();
  const {
    isPromoAchieved,
    promoSuccessLevel,
    secondListItemInCart,
    firstListCartQuantity,
    secondListCartQuantity,
    firstListCartValue,
    secondListCartValue
  } = usePromoCart();
  const { qualifyingImages, rewardImages } = useBogoImages();
  const { isRewardEnabled } = usePromoPresentation();
  const { setQuickviewItemDisplayPosition, setQuickviewItemId } = usePromoDrawerModel();

  const tgtListAlertText = useMessage(MESSAGE_KEY.tgtListAlertText);
  const tgtListSubtitle = useMessage(MESSAGE_KEY.tgtListSubtitle);
  const title = useMessage(
    type === 'card' ? MESSAGE_KEY.cardCtaHeaderText : MESSAGE_KEY.title
  );
  const tgtProductsText = useMessage(MESSAGE_KEY.tgtProducts, displayableSecondListItemIds.length);
  const missingQualifyDrawer2TextWarning = useMessage(MESSAGE_KEY.missingQualifyDrawer2TextWarning);
  const editQualifyText = useMessage(MESSAGE_KEY.editQualifyText);
  const progressBarStatusText = useMessage(MESSAGE_KEY.progressBarStatusText);
  const podCtaSuccessMessage = useMessage(MESSAGE_KEY.podCtaSuccessMessage);

  const openQuickview = useCallback(({ displayPosition, itemId }) => {
    setQuickviewItemId(itemId);
    setQuickviewItemDisplayPosition(displayPosition);
    onDrawerViewChange(DRAWER_TYPES.QUICKVIEW_DRAWER);
  }, [onDrawerViewChange, setQuickviewItemDisplayPosition, setQuickviewItemId]);

  const openPromoList1Drawer = useCallback(() => {
    onDrawerViewChange(DRAWER_TYPES.PROMO_LIST_1_DRAWER);
  }, [onDrawerViewChange]);

  const qualifyingThresholdForQuantityMet = firstListCartQuantity >= src1EligibilityCriteria.minPurchaseQuantity;
  const rewardThresholdForQuantityMet = secondListCartQuantity >= tgt1EligibilityCriteria.minPurchaseQuantity;
  const underQualifyingThresholdForQuantityMet = firstListCartQuantity < src1EligibilityCriteria.minPurchaseQuantity;
  const underQualifyingThresholdMet = firstListCartValue < src1EligibilityCriteria.minPurchaseAmount;
  const showBogoImageList = isForwardsB1gy || isForwardsBxg1;
  const showAlert = Boolean(tgtListAlertText);
  const showMissingQualifyItemsThreshold = isDollarThresholdBogo && !isPromoAchieved && underQualifyingThresholdMet;
  const showMissingQualifyItems = (isForwardsB1gy || isForwardsBxg1) && underQualifyingThresholdForQuantityMet;
  const showViewCartButton = (isDollarThresholdBogo && isPromoAchieved && secondListItemInCart)
    || ((isForwardsB1gy || isForwardsBxg1) && promoSuccessLevel === 'success');
  const showFooter = showViewCartButton || showMissingQualifyItems || showMissingQualifyItemsThreshold;
  const alertStatus = isRewardEnabled ? 'success' : 'info';
  const qualifyingThresholdMet = firstListCartValue >= src1EligibilityCriteria.minPurchaseAmount;
  const rewardThresholdMet = secondListCartValue >= tgt1EligibilityCriteria.minPurchaseAmount;

  const shouldShowConfirmedIcon = () => {
    if (isForwardsB1gy || isForwardsBxg1) {
      return qualifyingThresholdForQuantityMet && rewardThresholdForQuantityMet && Boolean(podCtaSuccessMessage);
    }
    if (isDollarThresholdBogo){
      return qualifyingThresholdMet && rewardThresholdMet && Boolean(podCtaSuccessMessage);
    }
    return false;
  };
  const showConfirmedIcon = shouldShowConfirmedIcon();

  return (
    <>
      <PromoDrawerHeader
        onBack={onBack}
        onClose={onClose}
        title={<Typography variant="h4">{title}</Typography>}
        data-testid="promo-list-2-header"
      />
      <div className="sui-top-[48px] sui-z-10 sui-sticky">
        <DrawerHeader
          className="sui-flex sui-flex-col sui-px-4 sui-pt-4 sui-pb-2 sui-bg-primary"
          data-testid="promo-list-2-drawer"
        >
          <OverflowContainer>
            {showBogoImageList && (
              <BogoProductImageList
                qualifyingImages={qualifyingImages}
                rewardImages={rewardImages}
                inDrawer2
              />
            )}
          </OverflowContainer>
        </DrawerHeader>
      </div>
      <DrawerBody
        className="sui-flex-auto sui-overflow-y-auto sui-bg-primary sui-px-4 sui-pt-2 sui-pb-0"
        data-testid="promo-list-2-drawer"
      >
        {showMissingQualifyItems && <Alert status="warning">{missingQualifyDrawer2TextWarning}</Alert>}
        {(showAlert && qualifyingThresholdMet) &&  <Alert status={alertStatus}>{tgtListAlertText}</Alert>}
        {(showAlert && !qualifyingThresholdMet) && <Alert className="sui-border-1 sui-border-solid sui-border-primary sui-rounded-base" status='warning'>{tgtListAlertText}</Alert>}
        <PromoList
          itemIds={displayableSecondListItemIds}
          listText={(
            <span className="sui-inline-flex sui-items-center sui-gap-2">
              {tgtListSubtitle && <Typography variant="h6">{tgtListSubtitle}</Typography>}
              {tgtProductsText && <Typography component="span" variant="body-lg">{tgtProductsText}</Typography>}
            </span>
          )}
          onProductPodClick={openQuickview}
          placeholderItemIds={secondListPlaceholderItemIds}
          observer={secondListObserver}
        />
      </DrawerBody>
      {showFooter && (
        <DrawerFooter
          data-testid="promo-list-2-drawer-footer"
          className="sui-bg-primary sui-p-4 sui-relative sui-bottom-0 sui-sticky sui-shadow-sm"
        >
          <ButtonGroup orientation="vertical">
            {showConfirmedIcon && (
              <Typography component="span" color="success" height="tight">
                {(isForwardsB1gy || isForwardsBxg1) ? progressBarStatusText : podCtaSuccessMessage}
              </Typography>
            )}
            {showViewCartButton && <ViewCartButton />}
            {(showMissingQualifyItems || showMissingQualifyItemsThreshold) && (              
            <Button fullWidth variant="primary" onClick={openPromoList1Drawer}>
                {editQualifyText}
              </Button>
            )}
          </ButtonGroup>
        </DrawerFooter>
      )}
    </>
  );
};

PromoList2Drawer.displayName = 'PromoList2Drawer';

PromoList2Drawer.propTypes = {
  onBack: func.isRequired,
  onClose: func.isRequired,
  onDrawerViewChange: func.isRequired
};

PromoList2Drawer.dataModel = PromoList2DrawerDataModel;
